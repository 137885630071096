@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../button/style/mixin';
@import './mixin';

@search-prefix: ~'@{ant-prefix}-input-search';

.@{search-prefix} {
  .@{ant-prefix}-input {
    border-right: 0;

    &:hover,
    &:focus {
      border-color: @input-hover-border-color;

      + .@{ant-prefix}-input-group-addon .@{search-prefix}-button:not(.@{ant-prefix}-btn-primary) {
        border-left-color: @input-hover-border-color;
      }
    }
  }

  // fix slight height diff in Firefox:
  // https://ant.design/components/auto-complete-cn/#components-auto-complete-demo-certain-category
  .@{ant-prefix}-input-lg {
    line-height: @line-height-base - 0.0002;
  }

  > .@{ant-prefix}-input-group {
    > .@{ant-prefix}-input-affix-wrapper {
      border-right: 0;
    }
    > .@{ant-prefix}-input-group-addon {
      padding: 0;
      border: 0;

      .@{search-prefix}-button {
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }

      .@{search-prefix}-button:not(.@{ant-prefix}-btn-primary) {
        color: @text-color-secondary;

        &.@{ant-prefix}-btn-loading::before {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
